/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// code-highlight in blog
require('prismjs/themes/prism-coy.css');
require('katex/dist/katex.min.css');
